<template>
  <div class="superContainer">
    <div class="showDictionary">

      <vue-confirm-dialog></vue-confirm-dialog>

      <div id="controlPanel" v-show="selectedTypes.length > 0">
        <div class="inlinePanel">
          <label class="numOfSelected">{{ selectedTypes.length }} {{ $t("dictionary.typesSelected") }}</label>
          <div class="fa fa-trash-o" @click="deleteAllSelectedTypes">
            <span class="deletehint">{{ $t("controlPanel.deleteHint") }}</span>
          </div>
        </div>
      </div>
      <div class="dictionaryTable">
        <button @click="addType()" type="button" id="createType" class="btn waves-effect waves-light">
          {{ $t("dictionary.createTicketType") }}<i class="fa fa-pencil right"></i>
        </button>
        <table>
          <thead>
          <tr>
            <th>
              <input type="checkbox" id="checkbox">
            </th>
            <th>{{ $t("dictionary.id") }}</th>
            <th>{{ $t("dictionary.name") }}</th>
            </tr>
          </thead>
          <tr v-if="addNewType">
            <td>{{ $t("dictionary.newId") }}</td>
            <td>
              <div class="input-field col">
                <input id="nameType" type="text" v-model="typeValue.name">
              </div>
            </td>
            <td>
              <div class="fa fa-check" @click="createNewTicketType()">
                <span class="checkhint">{{ $t("dictionary.save") }}</span>
              </div>
              <div class="fa fa-window-close" @click="cancelCreateType()">
                <span class="closehint">{{ $t("dictionary.cancel") }}</span>
              </div>
            </td>
          </tr>
          <tr v-for="type of types" :key="type.id">
            <td>
              <input
                  type="checkbox"
                  id="checkboxOn0"
                  v-if="!isMandatory(type)"
                  @change="addToTypes(type.id, $event)"
              >
              <input type="checkbox" disabled id="checkboxOff0" v-else>
            </td>
            <td>{{ type.id }}</td>
            <td>{{ type.name }}</td>
          </tr>
        </table>
      </div>
      <div id="controlPanelStatus" v-show="selectedStatuses.length > 0">
        <div class="inlinePanel">
          <label class="numOfSelected">{{ selectedStatuses.length }} {{ $t("dictionary.typesSelected") }}</label>
          <div class="fa fa-trash-o" @click="deleteAllSelectedStatuses">
            <span class="deletehint">{{ $t("controlPanel.deleteHint") }}</span>
          </div>
        </div>
      </div>
      <div class="dictionaryTable">
        <div class="statusHeader">
          <button @click="addStatus()" type="button" id="createStatus" class="btn waves-effect waves-light">
            {{ $t("dictionary.createTicketStatus") }}<i class="fa fa-pencil right"></i>
          </button>
        </div>
        <table id="status">
          <thead>
          <tr>
            <th><input type="checkbox" id="checkbox1"></th>
            <th>{{ $t("dictionary.id") }}</th>
            <th>{{ $t("dictionary.name") }}</th>
          </tr>
          </thead>
          <tr v-if="addNewStatus">
            <td>{{ $t("dictionary.newId") }}</td>
            <td>
              <div class="input-field col">
                <input id="nameStatus" type="text" v-model="statusValue.name">
              </div>
            </td>
            <td>
              <div class="fa fa-check" @click="createNewTicketStatus()">
                <span class="checkhint">{{ $t("dictionary.save") }}</span>
              </div>
              <div class="fa fa-window-close" @click="cancelCreateStatus()">
                <span class="closehint">{{ $t("dictionary.cancel") }}</span>
              </div>
            </td>
          </tr>
          <tr v-for="status of statuses" :key="status.id">
            <td>
              <input type="checkbox"
                     id="checkboxOn"
                     v-if="!isMandatory(status)"
                     @change="addToStatuses(status.id, $event)">
              <input type="checkbox" disabled id="checkboxOff" v-else>
            </td>
            <td>{{ status.id }}</td>
            <td>{{ status.name }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
import Link from "../utils/Link";
import axios from "axios";
import VueToast from "vue-toast-notification";
import VueConfirmDialog from "vue-confirm-dialog";
import Vue from "vue";

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

export default {
  data() {
    return {
      addNewStatus: false,
      addNewType: false,
      statuses: [],
      types: [],
      selected: false,
      typeValue: {},
      statusValue: {},
      dictionaryTableTicket: "",
      table: this.$t("dictionary.table"),
      selectedTypes: [],
      selectedStatuses: []
    };
  },

  beforeMount() {
  },

  mounted() {
    this.$emit("changeComponent", 'dictionary');
    this.getTicketStatuses();
    this.getTicketTypes();
  },

  methods: {
    cancelCreateStatus() {
      this.addNewStatus = false;
      this.statusValue.name = "";
    },

    cancelCreateType() {
      this.addNewType = false;
      this.typeValue.name = "";
    },

    isMandatory(item) {
      const mandatory = item != null ? item['mandatory'] : undefined;
      return  mandatory != null ? mandatory : false;
    },

    addStatus() {
      this.addNewStatus = true;
    },

    addType() {
      this.addNewType = true;
    },

    addToTypes(id, event) {
      if (event.target.checked) {
        this.selectedTypes.push(id);
      } else {
        this.selectedTypes.splice(this.selectedTypes.indexOf(id), 1);
      }
    },

    addToStatuses(id, event) {
      if (event.target.checked) {
        this.selectedStatuses.push(id);
      } else {
        this.selectedStatuses.splice(this.selectedStatuses.indexOf(id), 1);
      }
    },

    deleteType(id) {
      let headers = Link.methods.getHeaders();
      axios.delete(
          Link.methods.getTicketSettingTypeDelete(id),
          {headers}
      ).then(() =>
          this.getTicketTypes()
      );
      this.createToast(this.$t("dictionary.toastTypeDeleted", [id]));
      this.getTicketTypes();
    },

    deleteStatus(id) {
      let headers = Link.methods.getHeaders();
      axios.delete(
          Link.methods.getTicketSettingStatusDelete(id),
          {headers}
      ).then(() =>
          this.getTicketStatuses()
      );
      this.createToast(this.$t("dictionary.toastStatusDeleted", [id]), "success");
    },

    deleteAllSelectedTypes() {
      this.$confirm({
        message: this.$t("dictionary.typesDeleteConfirm"),
        button: {
          yes: this.$t("dictionary.yes"),
          no: this.$t("dictionary.no")
        },
        callback: confirm => {
          if (confirm) {
            for (let i = 0; i < this.selectedTypes.length; i++) {
              this.deleteType(this.selectedTypes[i]);
            }
          }
          this.selectedTypes = [];
        }
      });
    },

    deleteAllSelectedStatuses() {
      this.$confirm({
        message: this.$t("dictionary.statusesDeleteConfirm"),
        button: {
          yes: this.$t("dictionary.yes"),
          no: this.$t("dictionary.no")
        },
        callback: confirm => {
          if (confirm) {
            for (let i = 0; i < this.selectedStatuses.length; i++) {
              this.deleteStatus(this.selectedStatuses[i]);
            }
          }
          this.selectedStatuses = [];
        }
      });
    },

    getTicketTypes() {
      const headers = Link.methods.getHeaders()
      axios.get(
        Link.methods.getTicketSettingTypesUrl(),
        {headers}
      ).then(res => {
        this.types = res.data;
      });
    },

    getTicketStatuses() {
      const headers = Link.methods.getHeaders()
      axios.get(
        Link.methods.getTicketSettingStatusesUrl(),
        {headers}
      ).then(res => {
        this.statuses = res.data;
      });
    },

    createNewTicketType() {
      const headers = Link.methods.getHeaders();
      new Promise((resolve) => {
        axios.post(
            Link.methods.getNewTypeTicketCreateUrl(),
            this.typeValue,
            {headers}
        ).then(() => {
          this.createToast(this.$t("dictionary.typeCreated"), "success");
          this.getTicketTypes();
        }).catch(() => {
          this.createToast(this.$t('dictionary.typeErrorCreated'), "error");
        });
        resolve();
      }).then(() => {
        this.addNewType = false;
        this.typeValue.name = "";
      });
    },

    createNewTicketStatus() {
      const headers = Link.methods.getHeaders();
      new Promise((resolve) => {
        axios.post(
            Link.methods.getNewStatusTicketCreateUrl(),
            this.statusValue,
            {headers}
        ).then(() => {
          this.createToast(this.$t("dictionary.statusCreated"), "success");
          this.getTicketStatuses();
        }).catch(() => {
          this.createToast(this.$t('dictionary.statusErrorCreated'), "error");
        });
        resolve();
      }).then(() => {
        this.addNewStatus = false;
        this.statusValue.name = "";
      });
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 3000
      });
    },
  }
}
</script>


<style scoped lang="scss">
@import '../../../public/materialize-src/sass/materialize.scss';
@import "public/styles/vars.scss";
@import "../../../public/styles/dictionary";
@import "public/styles/hints.scss";

#checkboxOff, #checkboxOn, #checkboxOn0, #checkboxOff0, #checkbox, #checkbox1 {
  pointer-events: auto;
  opacity: 100;
  width: 24px;
  height: 24px;
  margin-top: -0.8%;
  margin-right: 3%;
  background: #FFFFFF;
  border: 2px solid #606060;
  box-sizing: border-box;
  border-radius: 4px;
}

#controlPanel {
  position: absolute;
  margin-left: 2%;
  width: 37%;
  margin-top: -6.6%;
  height: 9vh;
  align-content: normal;
  background-color: rgba(235, 34, 39, 0.07);
  border-radius: 8px
}

#controlPanelStatus {
  position: absolute;
  margin-left: 44.5%;
  width: 36%;
  margin-top: -6.6%;
  height: 9vh;
  align-content: normal;
  background-color: rgba(235, 34, 39, 0.07);
  border-radius: 8px
}

.deletehint {
  margin-top: 20px;
}

.inlinePanel {
  display: inline-block;
  margin-top: 5%;
  margin-right: 63%;
}

.numOfSelected {
  width: 100%;
  margin-right: 15px;
  color: black;
  font-size: 16px;
}

</style>